import { useEffect, useState } from 'react';

function useTypewriterEffect(texts, duration) {
  const [currentText, setCurrentText] = useState('');
  const [textIndex, setTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const currentDuration = isDeleting
      ? duration / (2 * texts[textIndex].length)
      : duration / texts[textIndex].length;

    const nextCharTimeout = setTimeout(() => {
      if (isDeleting) {
        setCurrentText((prevText) => prevText.slice(0, -1));
        setCharIndex((prevIndex) => prevIndex - 1);
      } else {
        setCurrentText((prevText) => prevText + texts[textIndex][charIndex]);
        setCharIndex((prevIndex) => prevIndex + 1);
      }
    }, currentDuration);

    if (!isDeleting && charIndex === texts[textIndex].length) {
      clearTimeout(nextCharTimeout);
      const waitTimeout = setTimeout(() => {
        setIsDeleting(true);
      }, duration);
      return () => clearTimeout(waitTimeout);
    }

    if (isDeleting && charIndex === 0) {
      clearTimeout(nextCharTimeout);
      setIsDeleting(false);
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }

    return () => {
      clearTimeout(nextCharTimeout);
    };
  }, [texts, textIndex, charIndex, isDeleting, duration]);

  return currentText;
}

export default useTypewriterEffect;
