import React from 'react';
import { useSelector } from 'react-redux';
import { AiFillLinkedin, AiFillGithub, AiOutlineMail } from 'react-icons/ai';
import { IoDocumentTextSharp } from 'react-icons/io5';
import '../styles/footer.css';

function Footer(props) {
  const theme = useSelector((state) => state.theme.mode);

  return (
    <div
      className={`footer-container ${theme === 'dark' ? 'footer-dark' : ''}`}
    >
      <MobileIcons />
      <CopyRight />
    </div>
  );
}

function MobileIcons() {
  const email = 'caleb.kim1@outlook.com';
  const size = 30;

  return (
    <div className='mobile-icons'>
      <div>
        <a href='https://www.linkedin.com/in/caleb-kim1' target='_blank'>
          <div className='mobile-icon'>
            <AiFillLinkedin size={size} />
          </div>
        </a>
      </div>
      <div>
        <a href='https://github.com/Swhag' target='_blank'>
          <div className='mobile-icon'>
            <AiFillGithub size={size} />
          </div>
        </a>
      </div>
      <div>
        <a
          href='https://drive.google.com/file/d/1XjeV5qWXkH4z4s_FDPknXW3OmV5G7vvj/view?usp=sharing'
          target='_blank'
        >
          <div className='mobile-icon'>
            <IoDocumentTextSharp size={size} />
          </div>
        </a>
      </div>
      <div>
        <a href={`mailto:${email}`}>
          <div className='mobile-icon'>
            <AiOutlineMail size={size} />
          </div>
        </a>
      </div>
    </div>
  );
}

function CopyRight() {
  return (
    <p className='copyright'>&copy; 2024 • Caleb Kim - All Rights Reserved</p>
  );
}

export default Footer;
