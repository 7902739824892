import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import useTypewriterEffect from '../utils/useTypewriterEffect';
import DownArrow from '../components/DownArrow';
import '../styles/hero.css';
import DancingLines from '../components/dancingLines/dancingLines';

function Hero(props) {
  const theme = useSelector((state) => state.theme.mode);
  const { scrollToSection, hero, about } = props;
  const [dynamicText, setDynamicText] = useState([
    'Frontend web developer',
    'Passionate about learning new technologies',
    'Enjoy creating interactive web applications',
  ]);

  const typewriterText = useTypewriterEffect(dynamicText, 2500);

  return (
    <div className={`hero ${theme === 'dark' ? 'hero-dark' : ''}`} ref={hero}>
      <DancingLines></DancingLines>

      <div className='hero-content'>
        <h1 className='line-one'>Hi,</h1>
        <h1 className='line-two'>My name is Caleb</h1>

        <p className='dynamic-line typewriter'>
          <span className='typewriter-text'>{typewriterText}</span>
          <span className='typewriter-cursor'>|</span>
        </p>

        <a
          href='https://drive.google.com/file/d/1XjeV5qWXkH4z4s_FDPknXW3OmV5G7vvj/view?usp=sharing'
          target='_blank'
        >
          <span className='resume-button'>View My Resume</span>
        </a>
      </div>
      <DownArrow scrollToSection={scrollToSection} about={about} />
    </div>
  );
}

export default Hero;
