import React from 'react';
import { useSelector } from 'react-redux';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  oneLight,
  nightOwl,
} from 'react-syntax-highlighter/dist/esm/styles/prism';

function MyCode(props) {
  const theme = useSelector((state) => state.theme.mode);
  const { codeString } = props;

  const macTerminalStyle = {
    backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.3)' : '#f4f4f4',
    boxShadow: '0 8px 14px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '15px',
    paddingTop: '10px',
    fontFamily:
      'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
    position: 'relative',
    overflow: 'auto',
  };

  const terminalButtonsStyle = {
    display: 'flex',
    gap: '6px',
    marginBottom: '10px',
  };

  const terminalLabelStyle = {
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '12px',
    color: theme === 'dark' ? '#BBBBBB' : '#000',
  };

  return (
    <div style={macTerminalStyle}>
      <div style={terminalButtonsStyle}>
        <TerminalButton color='#FF605C' />
        <TerminalButton color='#FFBD44' />
        <TerminalButton color='#00CA4E' />
      </div>
      <div style={terminalLabelStyle}>/index.js</div>
      <SyntaxHighlighter
        language='javascript'
        style={theme === 'dark' ? nightOwl : oneLight}
        wrapLines={true}
        showLineNumbers={true}
        customStyle={{ wordBreak: 'break-all' }}
      >
        {codeString}
      </SyntaxHighlighter>
    </div>
  );
}

function TerminalButton({ color }) {
  const terminalButtonStyle = (color) => ({
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: color,
  });

  return <div style={terminalButtonStyle(color)} />;
}

export default MyCode;
