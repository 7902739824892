import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import emailjs from '@emailjs/browser';
import MyCode from '../components/Codeblock';

import '../styles/contact.css';

function Contact({ contact }) {
  const theme = useSelector((state) => state.theme.mode);
  const [isOpen, setIsOpen] = useState(false);

  const { ref: contactForm, inView: formIsVisible } = useInView({
    rootMargin: '-100px',
  });
  const { ref: header, inView: headerVisible } = useInView({});

  const form = useRef();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [massage, setMessage] = useState('');

  const codeString = `
  const button = document.getElementById('#sendBtn');

  const message = {
    name: '${name}',
    email: '${email}',
    message: '${massage}',
  };
  
  button.addEventListener('click', () => {
    form.send(message);
  });
  `;

  function handleInputChange(e) {
    const { name, value } = e.target;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'user_email':
        setEmail(value);
        break;
      case 'message':
        setMessage(value);
        break;
      default:
        break;
    }
  }

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_m2rjxwm',
        'template_uq7lasq',
        e.target,
        '9mD_2hLIAeFjt_OmF'
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsOpen(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  }

  return (
    <>
      <div
        className={`contact section ${theme === 'dark' ? 'contact-dark' : ''}`}
        ref={contact}
      >
        <div
          className={`section-header ${headerVisible ? 'header-animate' : ''}`}
          ref={header}
        >
          <h1>CONTACT ME</h1>
        </div>
        <div
          className={`contact-container ${
            formIsVisible ? 'contact-animate' : ''
          }`}
          ref={contactForm}
        >
          <form className='contact-form' ref={form} onSubmit={sendEmail}>
            <div className='form-header'>
              <h4>Send me a message!</h4>
              <h4>caleb.kim1@outlook.com</h4>
            </div>

            <div>
              <label htmlFor='name'>Name</label>
              <input
                required
                type='text'
                name='name'
                id='name'
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor='email'>Email</label>
              <input
                required
                type='text'
                name='user_email'
                id='email'
                onChange={handleInputChange}
              />
            </div>

            <div>
              <label htmlFor='message'>Message</label>
              <textarea
                required
                rows='7'
                name='message'
                id='message'
                onChange={handleInputChange}
              ></textarea>
            </div>

            <button className='btn' type='submit'>
              Send
            </button>
          </form>

          <div className='contact-code'>
            <MyCode codeString={codeString} />
          </div>
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}></Modal>
    </>
  );
}

function Modal(props) {
  const { isOpen, setIsOpen } = props;

  return (
    <>
      {isOpen && (
        <div className='modal-overlay'>
          <div className='modal'>
            <p className='modal-message'>Thank you for contacting me!</p>
            <button onClick={() => setIsOpen(false)}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export default Contact;
