import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Hero from './sections/Hero';
import About from './sections/About';
import Skills from './sections/Skills';

import Projects from './sections/Projects';
import Contact from './sections/Contact';
import Footer from './components/Footer';
import ScrollTop from './components/ScrollTop';

import './styles/App.css';

function App() {
  const theme = useSelector((state) => state.theme.mode);

  const hero = useRef(null);
  const about = useRef(null);
  const projects = useRef(null);
  const contact = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop - 25,
      behavior: 'smooth',
    });
  };

  const mainStyle = {
    backgroundColor: theme === 'light' ? '#fff' : '#1a1a2d',
    color: theme === 'light' ? '#000' : '#f7f7f7',
  };

  return (
    <div className='App' style={mainStyle}>
      <Sidebar
        scrollToSection={scrollToSection}
        hero={hero}
        about={about}
        projects={projects}
        contact={contact}
      />

      <Navbar
        scrollToSection={scrollToSection}
        hero={hero}
        about={about}
        projects={projects}
        contact={contact}
      />

      <div className='main-container'>
        <Hero scrollToSection={scrollToSection} hero={hero} about={about} />

        <About
          scrollToSection={scrollToSection}
          about={about}
          contact={contact}
        />
        <Skills />

        <Projects projects={projects} />
        <Contact contact={contact} />
        <Footer />
        <ScrollTop />
      </div>
    </div>
  );
}

export default App;
