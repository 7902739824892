import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleShowNav } from '../redux/themeSlice';

function CloseIcon() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.mode);
  const color = theme === 'light' ? '#000' : '#fff';

  const handleClick = () => {
    dispatch(toggleShowNav());
  };

  return (
    <svg
      stroke={color}
      fill={color}
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='44'
      width='44'
      xmlns='http://www.w3.org/2000/svg'
      className='nav-close-button'
      onClick={handleClick}
    >
      <path
        fill='none'
        stroke={color}
        strokeWidth='2'
        d='M7,7 L17,17 M7,17 L17,7'
      />
    </svg>
  );
}

export default CloseIcon;
