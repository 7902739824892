import React from 'react';
import { useSelector } from 'react-redux';

import '../styles/downArrow.css';

function DownArrow(props) {
  const { scrollToSection, about } = props;
  const theme = useSelector((state) => state.theme.mode);

  const handleScroll = (event) => {
    event.preventDefault();
    scrollToSection(about);
  };

  return (
    <a
      href='#'
      className={`down-arrow ${theme === 'dark' ? 'down-arrow-dark' : ''}`}
      onClick={handleScroll}
    >
      <svg className='circle' xmlns='http://www.w3.org/2000/svg'>
        <g>
          <ellipse
            className='background'
            ry='60'
            rx='60'
            cy='62.5'
            cx='62.5'
            strokeWidth='1'
          />

          <ellipse
            className='foreground'
            ry='60'
            rx='60'
            cy='62.5'
            cx='62.5'
            strokeWidth='2'
          />
        </g>
      </svg>
    </a>
  );
}

export default DownArrow;
