import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleShowNav } from '../redux/themeSlice';

import { FiMenu } from 'react-icons/fi';
import { setTheme } from '../redux/themeSlice';
import LightMode from '../icons/LightMode';
import DarkMode from '../icons/DarkMode';
import '../styles/navToggles.css';

function NavToggles() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.mode);
  const color = theme === 'light' ? '#000' : '#fff';

  const toggleTheme = useCallback(() => {
    dispatch(setTheme(theme === 'light' ? 'dark' : 'light'));
  }, [dispatch, theme]);

  const handleClick = useCallback(() => {
    dispatch(toggleShowNav());
  }, [dispatch]);

  return (
    <div className='nav-toggles'>
      <div className='theme-toggle ' onClick={toggleTheme}>
        <div
          className={`${theme === 'dark' ? 'darkMode-spin' : 'lightMode-spin'}`}
        >
          {theme === 'dark' ? <LightMode /> : <DarkMode />}
        </div>
      </div>
      <div className='nav-menu-toggle' onClick={handleClick}>
        <FiMenu size={28} color={color} />
      </div>
    </div>
  );
}

export default NavToggles;
