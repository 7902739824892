import React, { useState, useEffect } from 'react';

function useActiveSection(props) {
  const { hero, about, projects, contact } = props;
  const [activeSection, setActiveSection] = useState('hero');

  useEffect(() => {
    const handleScroll = () => {
      if (
        !hero.current ||
        !about.current ||
        !projects.current ||
        !contact.current
      ) {
        return;
      }

      if (
        window.pageYOffset >= hero.current.offsetTop - 500 &&
        window.pageYOffset < about.current.offsetTop - 500
      ) {
        setActiveSection('hero');
      } else if (
        window.pageYOffset >= about.current.offsetTop - 500 &&
        window.pageYOffset < projects.current.offsetTop - 500
      ) {
        setActiveSection('about');
      } else if (
        window.pageYOffset >= projects.current.offsetTop - 500 &&
        window.pageYOffset < contact.current.offsetTop - 500
      ) {
        setActiveSection('projects');
      } else {
        setActiveSection('contact');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hero, about, projects, contact]);

  return activeSection;
}

export default useActiveSection;
