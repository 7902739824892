import React from 'react';
import { useSelector } from 'react-redux';

function DarkMode(props) {
  const theme = useSelector((state) => state.theme.mode);
  const color = theme === 'light' ? '#000' : '#fff';

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      width='24'
      height='24'
      fill={color}
    >
      <path
        d='M6 .278a.768.768 0 0 1 .08.858a7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277c.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316a.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71C0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z'
        fill={color}
      ></path>
    </svg>
  );
}

export default DarkMode;
