import { configureStore } from '@reduxjs/toolkit';
import themeSlice from './themeSlice';

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (error) {
    console.error('Error saving state to local storage:', error);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (error) {
    console.error('Error loading state from local storage:', error);
    return undefined;
  }
}

const preloadedState = loadFromLocalStorage();

export const store = configureStore({
  preloadedState,
  reducer: {
    theme: themeSlice.reducer,
  },
});

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});
