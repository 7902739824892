import { createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    mode: 'light',
    showNav: false,
  },

  reducers: {
    setTheme: (state, action) => {
      state.mode = action.payload;
    },

    toggleShowNav: (state) => {
      state.showNav = !state.showNav;
    },
  },
});

export const { setTheme, toggleShowNav } = themeSlice.actions;
export default themeSlice;
