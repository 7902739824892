import React from 'react';
import { useSelector } from 'react-redux';

import '../styles/projects.css';
import { useInView } from 'react-intersection-observer';
import { BiLinkExternal } from 'react-icons/bi';
import { HiOutlineCode } from 'react-icons/hi';

import portfolio from '../images/portfolio.png';
import cloud9 from '../images/cloud9.png';
import textats from '../images/textats.png';
import kickstore from '../images/kickstore.png';
import cvbuilder from '../images/cvbuilder.png';
import restaurant from '../images/restaurant.png';

function Projects(props) {
  const { projects } = props;
  const theme = useSelector((state) => state.theme.mode);
  const { ref: header, inView: headerVisible } = useInView({});

  const projectData = [
    {
      title: 'Portfolio Version 1.0',
      description:
        'Older version of personal portfolio webpage. It has much simpler design with basic animations. Created using React, particle-JS and CSS.',
      imgSrc: portfolio,
      liveAppLink: 'https://swhag.github.io/old-portfolio/',
      codeLink: 'https://github.com/Swhag/old-portfolio',
    },
    {
      title: 'Cloud9 - Weather Dashboard',
      description:
        'Cloud9 is a weather app with a fully responsive design. It provides users with up-to-date weather conditions and forecasts for any location. Developed with React, react-select, OpenWeatherMap API.',
      imgSrc: cloud9,
      liveAppLink: 'https://swhag.github.io/Cloud9/',
      codeLink: 'https://github.com/Swhag/Cloud9',
    },
    {
      title: 'Textats - Text Analyzer',
      description:
        'Textats is a user-friendly text analysis tool that provides character, word, letter statistics and generate a quick text summarization. Developed with React, ChartJS, MeaningCloud API.',
      imgSrc: textats,
      liveAppLink: 'https://swhag.github.io/Textats/',
      codeLink: 'https://github.com/Swhag/Textats',
    },
    {
      title: 'KickStore',
      description:
        'E-commerce web application that retrieves products from a mock database created by me. Users can add/delete/update products in the shopping cart. Built suing React, Redux, Axios, React Router, and Bootstrap.',
      imgSrc: kickstore,
      liveAppLink: 'https://swhag.github.io/React-E-Commerce-App/',
      codeLink: 'https://github.com/Swhag/React-E-Commerce-App',
    },
    {
      title: 'Resumer Builder',
      description:
        'Resume-Builder tool that allows users to load and save different states of input values and export the output resume as a PDF file. Built with React, React-to-print, UUID library, and CSS.',
      imgSrc: cvbuilder,
      liveAppLink: 'https://swhag.github.io/Resume-Builder-App/',
      codeLink: 'https://github.com/Swhag/Resume-Builder-App',
    },
    {
      title: 'Restaurant Landing Page',
      description:
        'Simple Ramen is a restaurant landing page made with the power of flex box and grid layout. Used HTML, CSS, JavaScript and JQuery.',
      imgSrc: restaurant,
      liveAppLink: 'https://swhag.github.io/Project-Restaurant-Page/',
      codeLink: 'https://github.com/Swhag/Project-Restaurant-Page',
    },
  ];

  return (
    <div
      className={`projects section ${theme === 'dark' ? 'projects-dark' : ''}`}
      ref={projects}
    >
      <div
        className={`section-header ${headerVisible ? 'header-animate' : ''}`}
        ref={header}
      >
        <h1>MY PROJECTS</h1>
      </div>
      <div className='projects-group'>
        {projectData.slice(0, 3).map((project, index) => (
          <Project key={index} {...project} />
        ))}
      </div>

      <div className='projects-group'>
        {projectData.slice(3, 6).map((project, index) => (
          <Project key={index + 3} {...project} />
        ))}
      </div>
    </div>
  );
}

function Project(props) {
  const { title, description, imgSrc, liveAppLink, codeLink } = props;
  const { ref, inView: myElementIsVisible } = useInView({
    rootMargin: '-100px',
  });

  return (
    <div
      className={`project ${myElementIsVisible ? 'project-animate' : ''}`}
      ref={ref}
    >
      <div className='project-image'>
        <img src={imgSrc} />
      </div>
      <div className='project-content'>
        <h3>{title}</h3>
        <p>{description}</p>
        <div className='button-group'>
          <a className='btn' href={liveAppLink} target='_blank'>
            <span>Live App</span>
            <div className='icon-container'>
              <BiLinkExternal size={22} />
            </div>
          </a>
          <a className='btn' href={codeLink} target='_blank'>
            <span>Code</span>
            <div className='icon-container'>
              <HiOutlineCode size={22} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Projects;
