import React from 'react';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated, config } from 'react-spring';

import HTMLIcon from '../icons/skills/HTMl_Icon';
import CSSIcon from '../icons/skills/CSS_Icon';
import JavaScriptIcon from '../icons/skills/Javascript_Icon';
import ReactIcon from '../icons/skills/React_Icon';
import NodeJsIcon from '../icons/skills/NodeJs_Icon';
import ReduxIcon from '../icons/skills/Redux_Icon';
import JQueryIcon from '../icons/skills/JQuery_Icon';
import SASSIcon from '../icons/skills/SASS_Icon';
import BootstrapIcon from '../icons/skills/Bootstrap_Icon';
import GitIcon from '../icons/skills/Git_Icon';
import NextJsIcon from '../icons/skills/NextJs_Icon';
import WebpackIcon from '../icons/skills/Webpack_Icon';
import MongoDBIcon from '../icons/skills/MongoDB_Icon';

import '../styles/skills.css';

function Skills() {
  const { ref, inView: myElementIsVisible } = useInView();
  const { ref: header, inView: headerVisible } = useInView({});
  const theme = useSelector((state) => state.theme.mode);

  const skills = [
    { icon: <HTMLIcon />, label: 'HTML' },
    { icon: <CSSIcon />, label: 'CSS3' },
    { icon: <JavaScriptIcon />, label: 'JavaScript' },
    { icon: <ReactIcon />, label: 'React' },
    { icon: <NodeJsIcon />, label: 'NodeJS' },
    { icon: <NextJsIcon />, label: 'NextJS' },
    { icon: <ReduxIcon />, label: 'Redux' },
    { icon: <JQueryIcon />, label: 'JQuery' },
    { icon: <SASSIcon />, label: 'SASS' },
    { icon: <BootstrapIcon />, label: 'Bootstrap' },
    { icon: <GitIcon />, label: 'Git' },
    { icon: <WebpackIcon />, label: 'Webpack' },
    { icon: <MongoDBIcon />, label: 'MongoDB' },
  ];

  return (
    <div className={`skills section ${theme === 'dark' ? 'skills-dark' : ''}`}>
      <div
        className={`section-header ${headerVisible ? 'header-animate' : ''}`}
        ref={header}
      >
        <h1>MY SKILLS</h1>
      </div>
      <div className='skills-container' ref={ref}>
        {skills.map((skill, index) => (
          <Skill
            key={index}
            skill={skill}
            index={index}
            inView={myElementIsVisible}
          />
        ))}
      </div>
    </div>
  );
}

function Skill({ skill, index, inView }) {
  const props = useSpring({
    delay: inView ? index * 100 : 0,
    from: { transform: 'translateY(0px)' },
    to: [{ transform: 'translateY(-10px)' }, { transform: 'translateY(0px)' }],
    config: config.wobbly,
    reset: false,
  });

  return (
    <div className='skill-wrapper'>
      <animated.div style={props}>
        <div className='skill-icon'>{skill.icon}</div>
        <span className='skill-label'>{skill.label}</span>
      </animated.div>
    </div>
  );
}

export default Skills;
