import React from 'react';

function CVIcon(props) {
  return (
    <svg viewBox='0 0 910.812 910.812' {...props}>
      <path
        fill='#fff'
        d='M122.483 50v810.812c0 27.613 22.386 50 50 50h383.655V758.703H223.57c-8.284 0-15-6.717-15-15 0-8.285 6.716-15 15-15h333.404c4.744-28.377 29.462-50.076 59.164-50.076h172.183V50c0-27.614-22.386-50-50-50H172.483C144.868 0 122.483 22.386 122.483 50z M514.657 94.062l42.478 151.899c9.694 34.166 16.621 63.715 26.777 98.342h1.848c10.156-34.627 17.082-64.176 26.316-98.342l42.016-151.899h54.941l-93.725 301.952h-63.253L457.868 94.062H514.657zM358.609 88.522c36.475 0 66.947 18.006 85.877 38.321l-30.011 33.243c-15.236-15.236-32.319-24.932-55.404-24.932-49.864 0-84.953 41.553-84.953 109.423 0 68.794 32.319 110.347 83.106 110.347 26.777 0 46.17-11.081 63.253-30.011l30.01 32.781c-24.47 28.164-56.327 43.862-94.187 43.862-77.104 0-137.125-55.404-137.125-155.593C219.176 147.158 281.043 88.522 358.609 88.522zM223.57 471.627h463.664c8.284 0 15 6.717 15 15s-6.716 15-15 15H223.57c-8.284 0-15-6.717-15-15s6.716-15 15-15zM223.57 604.709h463.664c8.284 0 15 6.717 15 15 0 8.285-6.716 15-15 15H223.57c-8.284 0-15-6.715-15-15 0-8.284 6.716-15 15-15z'
      />
    </svg>
  );
}

export default CVIcon;
