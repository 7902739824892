import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useActiveSection from '../utils/useActiveSection';
import CloseIcon from '../icons/CloseIcon';
import NavToggles from './NavToggles';
import '../styles/navbar.css';

function Navbar(props) {
  const theme = useSelector((state) => state.theme.mode);
  const showNav = useSelector((state) => state.theme.showNav);

  const { hero, about, projects, contact, scrollToSection } = props;
  const activeSection = useActiveSection(props);

  return (
    <div
      className={`navbar-container ${
        theme === 'dark' ? 'navbar-container-dark' : ''
      }`}
    >
      <div
        className={`navbar ${theme === 'dark' ? 'nav-dark' : ''}${
          showNav ? ' show-navbar' : ''
        } `}
      >
        <ul>
          <NavbarItem
            active={activeSection === 'hero'}
            onClick={() => scrollToSection(hero)}
            label='Home'
          />
          <NavbarItem
            active={activeSection === 'about'}
            onClick={() => scrollToSection(about)}
            label='About'
          />
          <NavbarItem
            active={activeSection === 'projects'}
            onClick={() => scrollToSection(projects)}
            label='Projects'
          />
          <NavbarItem
            active={activeSection === 'contact'}
            onClick={() => scrollToSection(contact)}
            label='Contact'
          />
        </ul>
        <CloseIcon />
      </div>
      <NavToggles />
    </div>
  );
}

function NavbarItem(props) {
  const { active, onClick, label } = props;
  return (
    <li className={`${active ? 'active' : ''}`} onClick={onClick}>
      <span>{label}</span>
    </li>
  );
}

export default Navbar;
