import React from 'react';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import MyCode from '../components/Codeblock';
import '../styles/about.css';

function About(props) {
  const theme = useSelector((state) => state.theme.mode);
  const { scrollToSection, about, contact } = props;
  const { ref: content, inView: contentVisible } = useInView({});
  const { ref: header, inView: headerVisible } = useInView({});

  const codeString = `
  class Person {
    constructor() {
      this.name = 'Caleb';
      this.humor = 'dry';
      this.codes = true;
    }

    printJoke() {
      let joke = 'I love in-line CSS',
      return console.log(joke);
    }
  }

  function startCoding() {
    try {
      writeMyCode();
    } 
    catch {
      console.error('Looks like someone needs more coffee...');
    }
  }
  `;

  return (
    <div
      className={`about section ${theme === 'dark' ? 'about-dark' : ''}`}
      ref={about}
    >
      <div
        className={`section-header ${headerVisible ? 'header-animate' : ''}`}
        ref={header}
      >
        <h1>ABOUT ME</h1>
      </div>
      <div
        className={`about-content ${contentVisible ? 'content-animate' : ''}`}
        ref={content}
      >
        <div className='content-left'>
          <h3>Get to know me!</h3>
          <p>
            Hello there! My name is Caleb, and I am a Web Developer with a
            background in IT and Cybersecurity. Currently, I work as a Systems
            Analyst, but I am eager to transition into a role that allows me to
            pursue my passion for web development.
          </p>

          <p>
            My main focus as a Web Developer is on the practical and minimalist
            design. I aim to create websites that are not only visually
            appealing but also perform well and provide an exceptional user
            experience. Additionally, I have a strong grasp of vanilla
            JavaScript/CSS fundamentals and am ready to adapt to any framework
            as needed.
          </p>

          <p>
            Thank you for checking out my portfolio! If you have any questions
            or would like to discuss potential opportunities, please feel free
            to reach out.
          </p>

          <div className='btn-container'>
            <button className='btn' onClick={() => scrollToSection(contact)}>
              Contact
            </button>
          </div>
        </div>

        <div className='content-right'>
          <MyCode codeString={codeString} />
        </div>
      </div>
    </div>
  );
}

export default About;
